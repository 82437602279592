/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-nested-ternary */
import { ChangeEventHandler, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import { AbstractMainFilter, MainFilterColorValue } from '@/core/model/main-filter/type';
import { ColorHex, Src } from '@/shared/types';
import { PropsWithClassName } from '@/shared/types/util';
import Radio from '@/shared/ui/Form/Radio';
import SvgIcon from 'components/SvgIcon';

import { NEXT_PUBLIC_CDN_ROOT } from '../../../../../cfg';
import Button from '../../../../../controls/Button';
import Checkbox from '../../../../../controls/CheckboxField';
import Markdown from '../../../../../controls/Markdown';

import styles from './Filters.module.css';

import linkArrowIcon from '/assets/imgs/main/link-arrow.svg';

interface FilterTabPanelProps extends PropsWithClassName {
  items?: AbstractMainFilter[];
  filter: 'price' | 'shape' | 'size' | 'color';
  description;
}

interface CurrentTabPanelProps extends PropsWithClassName {
  items?: AbstractMainFilter[];
  selected: boolean;
}

const ExtendedMarkdown = Markdown as FC<{ source: string }>;
const ExtendedButton = Button as FC<{ className: string; href: string; onClick: () => void } & PropsWithChildren>;
const ExtendedCheckbox = Checkbox as FC<
  {
    input: { name: string; value: string; checked: boolean; onChange: () => void };
  } & PropsWithChildren
>;

const injectStyle = ({ rgb, image }: { rgb?: ColorHex; image?: Src } = {}) =>
  rgb ? { backgroundColor: `#${rgb}` } : image ? { backgroundImage: `url('${NEXT_PUBLIC_CDN_ROOT}${image}')` } : {};

export const FilterTabPanel = (props: FilterTabPanelProps) => {
  const { filter, items, description, className } = props;

  const [selectedItems, setSelectedItems] = useState({});

  const updateDataLayer = useCallback(() => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Главная страница',
      eventAction: 'Фильтры',
      eventLabel: 'Выбор параметров',
    });
  }, [])

  type THandleRadioChange = (item: AbstractMainFilter) => ChangeEventHandler<HTMLInputElement>
  const handleSelectedRadioChange = useCallback<THandleRadioChange>((item) => () => {
    setSelectedItems({[item.id]: item});
    updateDataLayer();
  }, [updateDataLayer])

  type THandleCheckboxChange = (item: AbstractMainFilter) => ChangeEventHandler<HTMLInputElement>
  const handleSelectedCheckboxChange = useCallback<THandleCheckboxChange>((item) => (event) => {
    if (event.target.checked) {
      setSelectedItems(prevState => ({ ...prevState, [item.id]: item }));
    } else {
      setSelectedItems((prevState) => Object.keys(prevState).reduce((acc, val) => {
        if (val === item.id) {
          return acc;
        }

        return {...acc, [val]: item}
      }, {}))
    }

    updateDataLayer();
  }, [updateDataLayer]);

  const renderFilterAction = useCallback((type: FilterTabPanelProps['filter'], item: AbstractMainFilter) => {
    switch (type) {
      case 'size':
      case 'price': {
        return <Radio id={`${filter}-${item.id}`} name={`mainFilter_${filter}`} value={item.value} active={Boolean(selectedItems[item.id])} onChange={handleSelectedRadioChange(item)}>
          <span>{item.name}</span>
        </Radio>
      }

      case 'shape': {
        return <ExtendedCheckbox
          input={{
            name: `${filter}-${item.value}`,
            value: item.value,
            checked: !!selectedItems[item.id],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange: handleSelectedCheckboxChange(item),
          }}
        >
          <span>{item.name}</span>
        </ExtendedCheckbox>
      }

      case 'color': {
        return <input type='checkbox'
          checked={Boolean(selectedItems[item.id])}
          aria-label={item.name}
          key={`tab-color-${item.id}`}
          className={classnames(styles.tabsItemColorFilterItem, {
            [styles.selected]: !!selectedItems[item.id]
          })}
          style={injectStyle({
            rgb: (item as MainFilterColorValue).colorHex,
            image: (item as MainFilterColorValue).imageSrc
          })}
          onChange={handleSelectedCheckboxChange(item)}
        />;
      }

      default: {
        return null;
      }
    }
  }, [filter, handleSelectedCheckboxChange, handleSelectedRadioChange, selectedItems]);

  const isEmpty = !Object.keys(selectedItems).some((key) => !!selectedItems[key]);
  const selectedValues = Object.keys(selectedItems)
    .filter((key) => selectedItems[key] && selectedItems[key].value)
    .map((key) => items?.find((item) => item.id === key)?.value);

  const url = useMemo<string>(() => {
    if (isEmpty) {
      return '/catalog';
    }

    const [firstKey] = Object.keys(selectedItems);
    if (firstKey && selectedItems[firstKey].categoryCode && Object.keys(selectedItems).length === 1) {
      return `/catalog/${selectedItems[firstKey].categoryCode}`;
    }

    return `/catalog/f/${filter}-${selectedValues.join(',')}`;
  }, [filter, isEmpty, selectedItems, selectedValues]);

  if (!items?.length) return null;

  return (
    <div className={classnames(styles.tabsItem, className)}>
      <div className={styles.tabsItemHeader}>
        <ExtendedMarkdown source={description} />
      </div>
      <div className={styles.tabsItemContent}>
        <div className={classnames({[styles.tabsItemFilter]: filter !== 'color', [styles.tabsItemColorFilter]: filter === 'color'})}>
          {items?.length &&
            items
              .map((item, i) => (
                <div className={classnames({[styles.tabsItemColorFilter]: filter === 'color', [styles.tabsItemFilterItem]: filter !== 'color'})} key={`${filter} + ${i}`}>
                  {renderFilterAction(filter, item)}
                </div>
              ))}
        </div>

        <ExtendedButton
          className={styles.tabsItemButton}
          href={url}
          onClick={() => {
            window.dataLayer?.push({
              event: 'autoEvent',
              eventCategory: 'Главная страница',
              eventAction: 'Фильтры',
              eventLabel: 'Переход в каталог',
            });
          }}
        >
          Посмотреть ковры в каталоге
          <SvgIcon svg={linkArrowIcon} />
        </ExtendedButton>
      </div>
    </div>
  );
};

export const ColorFilterTabPanel = ({ items, className }: PropsWithClassName<{ items?: MainFilterColorValue[] }>) => {
  const [selectedItems, setSelectedItems] = useState({});

  const isEmpty = !Object.keys(selectedItems).some((key) => !!selectedItems[key]);
  const selectedValues = Object.keys(selectedItems)
    .filter((key) => !!selectedItems[key])
    .map((key) => items?.find((item) => item.id === key)?.value);

  if (!items?.length) return null;

  return (
    <div className={classnames(styles.tabsItem, className)}>
      <div className={styles.tabsItemHeader}>
        <ExtendedMarkdown source='#### Определите 3 главных цвета в Вашей комнате и выберите один или больше в фильтре.' />
      </div>
      <div className={styles.tabsItemContent}>
        <div className={styles.tabsItemColorFilter}>
          {items?.length &&
            items
              .map((item) => {
                const itemStyle = injectStyle({ rgb: item.colorHex, image: item.imageSrc });

                return (
                  <a
                    aria-label={item.name}
                    key={`tab-color-${item.id}`}
                    className={classnames(styles.tabsItemColorFilterItem, {
                      [styles.selected]: !!selectedItems[item.id],
                    })}
                    style={itemStyle}
                    href={`/catalog/f/color-${item.value}/`}
                    onClick={(e) => {
                      setSelectedItems({ ...selectedItems, [item.id]: !selectedItems[item.id] });

                      window.dataLayer?.push({
                        event: 'autoEvent',
                        eventCategory: 'Главная страница',
                        eventAction: 'Фильтры',
                        eventLabel: 'Выбор параметров',
                      });

                      e.preventDefault();
                    }}
                  />
                );
              })}
        </div>

        <ExtendedButton
          className={styles.tabsItemButton}
          href={`/catalog${isEmpty ? '' : `/f/color-${selectedValues.join(',')}`}`}
          onClick={() => {
            window.dataLayer?.push({
              event: 'autoEvent',
              eventCategory: 'Главная страница',
              eventAction: 'Фильтры',
              eventLabel: 'Переход в каталог',
            });
          }}
        >
          Посмотреть ковры в каталоге
          <SvgIcon svg={linkArrowIcon} />
        </ExtendedButton>
      </div>
    </div>
  );
};

export const ColorFilterTabPanel2 = ({items, selected, className}: CurrentTabPanelProps) => (
  <FilterTabPanel className={classnames(className, { [styles.selected]: selected })} filter='color' items={items} description='#### Определите 3 главных цвета в Вашей комнате и выберите один или больше в фильтре.' />
)

export const SizeFilterTabPanel = ({ items, selected, className }: CurrentTabPanelProps) => (
  <FilterTabPanel
    className={classnames(className, { [styles.selected]: selected })}
    items={items}
    filter='size'
    description={`
#### Измерьте вашу комнату или пространство которое займет ковер. Подумайте, нужно ли чтобы мебель стояла на ковре. Ковер перед кроватью или под столом, где вы встречаете гостей или работаете, сохранит ваши ноги в тепле и подарит приятные тактильные ощущения.
    `}
  />
);

export const PriceFilterTabPanel = ({ items, selected, className }: CurrentTabPanelProps) => (
  <FilterTabPanel
    className={classnames(className, { [styles.selected]: selected })}
    items={items}
    filter='price'
    description={`
#### Как определиться с бюджетом на ковер? Мы рекомендуем простое правило: выбирайте ковер опираясь на стоимость основной мебельной группы в интерьере. Ориентир - не менее 50% от её стоимости. Более дорогой ковер может добавить роскоши всему интерьеру и наоборот.
    `}
  />
);

export const ShapeFilterTabPanel = ({ items, selected, className }: CurrentTabPanelProps) => (
  <FilterTabPanel
    className={classnames(className, { [styles.selected]: selected })}
    items={items}
    filter='shape'
    description={`
#### Прямоугольные ковры - универсальный выбор. Подходят для большинства ситуаций. Круглые и овальные прекрасно зонируют помещение. Например, так вы легко выделите зону для общения за общим столом или обеденную зону в столовой или гостиной. Квадратные ковры сбалансируют квадратную комнату.
    `}
  />
);
