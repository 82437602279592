/* eslint-disable no-nested-ternary */
import { ForwardedRef, forwardRef, InputHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { Id } from '@/shared/types';
import { PropsWithClassName } from '@/shared/types/util';

import Typography from '../../Typography';
import { FieldValidation } from '../type';

import style from './Radio.module.css';

export interface RadioState {
  active?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'checked'>, PropsWithChildren {
  id: string;
  error?: boolean;
  active?: boolean;
  renderBody?: (state: RadioState) => ReactNode;
}

export interface RadioGroupProps<RenderItem = Record<string, any>> extends PropsWithChildren, PropsWithClassName {
  name: string;
  list: (RenderItem & Omit<Partial<RadioProps>, 'id'> & { id: Id })[];
  defaultValue?: string;
  rules?: FieldValidation;
  renderItem?: (value: (RenderItem & RadioProps) & { field: ControllerRenderProps }) => ReactNode;
}

const RadioIcon = ({ disabled, active, error, className }: RadioState) => (
  <span
    className={classNames(style.icon, `radio__icon`, className, {
      [style.disabled]: disabled,
      [style.checked]: active,
      [style.error]: error,
    })}
  />
);

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { active, children, className, disabled, id, error, renderBody, onChange, value, ...restInputAttributes } =
    props;
  

  const classes = classNames(
    style.radio,
    { [style.disabled]: disabled, [style.error]: error },
    `radio-button`,
    className
  );

  return (
    <label className={classes} htmlFor={id}>
      <input
        {...restInputAttributes}
        onChange={onChange}
        id={id}
        className={style.input}
        disabled={disabled}
        value={value}
        ref={ref}
        type='radio'
        checked={active}
      />

      {renderBody?.({
        active,
        disabled,
        error,
      }) ?? (
        <>
          <RadioIcon className={style.radioIcon} disabled={disabled} error={error} />

          <Typography inherited variant='text90'>
            {children}
          </Typography>
        </>
      )}
    </label>
  );
});

const RadioGroupOrigin = (props: RadioGroupProps, ref) => {
  const { name, className, children, list, renderItem, ...restProps } = props;

  if (!list?.length) {
    return null;
  }

  return (
    <Controller
      name={name}
      {...restProps}
      render={({ field }) => (
        <fieldset ref={ref} className={classNames(style.fieldset, className, `radio-group`)}>
          {list.map((item) => {
            const { id, value, ...rest } = item;
            const active = field.value === value;

            if (renderItem) {
              return renderItem({
                field,
                ...item,
                id: String(id),
                name,
                active
              });
            }

            return <Radio {...rest} {...field} key={id} id={String(id)} name={name} value={value} active={active} />;
          })}

          {children}
        </fieldset>
      )}
    />
  );
};

type SelectOriginWithForwardedRef = <RenderItem extends Record<string, any>>(
  props: RadioGroupProps<RenderItem> & { ref?: ForwardedRef<HTMLFieldSetElement> }
) => ReturnType<typeof RadioGroupOrigin>;

export const RadioGroup = forwardRef(RadioGroupOrigin) as SelectOriginWithForwardedRef;

Radio.displayName = 'Radio';

export default Radio;
